function processTab(tabs, tab) {
  var card_id, form, fields, dirty;

  dirty = false;
  card_id = tabs.find('.tab.active').data('show');
  form = $(card_id).find('form');
  if (form) {
    resetForm(form);
    fields = form.find('input, select, textarea');

    $(fields).each(function(idx, field){
      if ($(field).val() != $(field).data('orig')) {
        form.find('.warning-notification').removeClass('d-none');
        dirty = true;
        return false;
      }
    });

    if (dirty) {
      return;
    }
  }

  $(tabs).find('.tab').removeClass('active');
  $(tab).addClass('active');
  var hide = $(tab).data('hide');
  var show = $(tab).data('show');
  $(hide).parent().addClass('d-none');
  $(show).parent().removeClass('d-none');
}
